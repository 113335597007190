import React, { useContext, useState, useEffect } from "react";
import _ from "lodash";
import useMediaQuery from "@mui/material/useMediaQuery";

import { Translate, Localize, translate } from "react-i18nify";
import { QRCode } from "react-qrcode-logo";

import { kioskstyles } from "./../../../../kioskstyles";

import "react-phone-number-input/style.css";

import { Button, Grid, Typography, IconButton, TextField } from "@mui/material";

import Logo from "./../../Components/Logo";
import KioskStepper from "./../../Components/Stepper/KioskStepper";
import PhoneKeypad from "./../../Components/CustomerPhoneNumber/PhoneKeypad";
import PhoneConfirm from "./../../Components/CustomerPhoneNumber/PhoneConfirm";
import IdleTimerDisplay from "./../../Components/Shared/IdleTimerDisplay";
import SubtitleWrapper from "./../../Components/Shared/PanelElements/SubtitleWrapper";
import FlexLeftPanel from "./../../Components/Shared/PanelElements/FlexLeftPanel";
import FlexRightPanel from "./../../Components/Shared/PanelElements/FlexRightPanel";
import PhoneDisplay from "./../../Components/CustomerPhoneNumber/PhoneDisplay";
import BackspaceButton from "./../../Components/CustomerPhoneNumber/BackspaceButton";

import SocketIOContext from "../../../../contexts/Kiosk/SocketProvider/SocketIOContext";

import { useTheme } from "@mui/material/styles";

import { Dlog } from "./../../../../utils/Dlog";

import HttpContext from "../../../../contexts/HTTP/HttpContext";
import KioskContext from "../../../../contexts/Kiosk/KioskContext";

import { useNavigate } from "react-router-dom";

import { setTranslations, setLocale, getLocale } from "react-i18nify";

import { IdleTimerContext, IdleTimerProvider } from "react-idle-timer";

//import HorizontalLayout from "./Components/CustomerPhoneNumber/HorizontalLayout";
//import VerticalLayout from "./Components/CustomerPhoneNumber/VerticalLayout";

let env = require("./../../../../env");

function displayError(errorCode) {
  switch (errorCode) {
    case 400:
      return translate("application.phonenumber.errors.unablefetchaccount");
    case 401:
      return translate("application.phonenumber.errors.invalidphone");
    case 404:
      return translate("application.errors.invalidcredentials");
    case 503:
      return translate("application.errors.serverunavailable");
    default:
      return translate("application.errors.unknownerror");
  }
}

export default function HorizontalLayout({
  setKioskkeyFunc,
  nextFunc,
  loading,
  //errorMessage,
  isNotMobile,
  kioskkey
}) {
  const theme = useTheme();
  const { simplepost } = useContext(HttpContext);

  //const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const {
    //customerName,
    customerFirstName,
    customerLastName,
    customerID,
    setCustomerNameAndID,
    numBags,
    setNumBags,
    numLabels,
    setLabels,
    sessionID,
    setAll,
    customerReset
  } = useContext(KioskContext);

  const {
    isConnected,
    socketEvents,
    qrCode,
    connect,
    disconnect,
    getSocket,
    sendEvent,
    clearSocketEvents
  } = useContext(SocketIOContext);

  const [phonenumber, setPhonenumber] = useState("");
  const [showconfirmdialog, setShowconfirmdialog] = useState(false);

  const [interval, setInterval] = useState(null);

  const navigate = useNavigate();

  const keypadDone = async () => {
    console.log("next called");

    //Attempt Kiosk Login
    const res = await simplepost("/kiosk/getCustomer", {
      type: "phone",
      value: phonenumber
    });

    //console.log("CPN DEBUG res.data", res.data);

    //var customerName = res.data.name;
    var customerFirstName = res.data.firstName;
    var customerLastName = res.data.lastName;
    var customerID = res.data.uniqueID;

    if (res.status >= 400) {
      setErrorMessage(displayError(res.status));
      return;
    }

    setAll(
      //customerName, //customerName
      customerFirstName, //customerFirstName
      customerLastName, //customerLastName
      customerID, //customerID
      null, //numBags
      null, //numLabels
      null, //labelsList
      sessionID //sessionID
    );

    setErrorMessage("");
    setShowconfirmdialog(true);
  };

  const hideConfirmDialog = async () => {
    setPhonenumber("");
    setShowconfirmdialog(false);
  };

  const displaystyleForconfirmdialog = async () => {
    Dlog("displaystyleForconfirmdialog", showconfirmdialog);

    if (showconfirmdialog) {
      return { display: "none" };
    } else {
      return { display: "block" };
    }
  };

  const backspacepress = async () => {
    let newNumber = phonenumber;

    if (newNumber.length > 0) {
      newNumber = newNumber.substring(0, newNumber.length - 1);
    }

    setPhonenumber(newNumber);
  };

  const next = async () => {
    navigate("/numbags");
  };

  const onIdle = async () => {
    if (env.IDLETIMER) {
      navigate("/language");
    }
  };

  // const isNotMobile = useMediaQuery("(min-width:600px)");
  // const isLandscape = useMediaQuery("(orientation:landscape)");

  // Dlog("locale", getLocale());

  return (
    <IdleTimerProvider timeout={30000} onIdle={onIdle}>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        style={kioskstyles.GridFullHeightFix}
      >
        <FlexLeftPanel isNotMobile={isNotMobile}>
          <div style={kioskstyles.flexLeftPanelContents}>
            <div style={kioskstyles.flexParentPanel}>
              <div style={kioskstyles.flexPanelLayoutB_TopContainer}>
                <Logo />
              </div>
              <div
                style={{
                  ...kioskstyles.flexPanelLayoutB_MidContainer,
                  flexDirection: "row",
                  alignContent: "space-between",
                  flexWrap: "wrap"
                }}
              >
                <div
                  class="fontSecondaryText"
                  style={kioskstyles.PleaseSignInElement}
                >
                  <Translate value="application.phonenumber.pleasesignin" />
                </div>
                <div>
                  <div
                    style={{
                      ...kioskstyles.blackCornedDiv,
                      ...kioskstyles.blackCornedDivMarginForSubtitle
                    }}
                  >
                    <span
                      class="fontBold"
                      style={{
                        color: "white",
                        textAlign: "center",
                        fontSize: "clamp(12px, 4vh, 34px)"
                      }}
                    >
                      <Translate value="application.phonenumber.donthaveaccount" />
                    </span>
                    <br />
                    <div
                      style={{
                        background: "white",
                        padding: ".5vh",
                        width: "19vh",
                        height: "19vh"
                      }}
                    >
                      <QRCode
                        style={{
                          height: "auto",
                          width: "auto",
                          maxWidth: "100%",
                          maxHeight: "100%"
                        }}
                        size="600"
                        fgColor="#27a737"
                        logoImage="/img/ca.svg"
                        qrStyle="dots"
                        removeQrCodeBehindLogo="true"
                        eyeRadius={{ outer: 5, inner: 1 }}
                        logoPadding="3"
                        //value="https://app.consignaction.com/qs/{{qrCode}}"
                        value={env.CAAPP_URL + "/qs/" + qrCode}
                      />
                    </div>
                    <br />
                    <span
                      class="fontBold"
                      style={{
                        color: "white",
                        textAlign: "center",
                        fontSize: "clamp(9px, 3vw, 24px)"
                      }}
                    >
                      <Translate value="application.phonenumber.scanqr" />
                    </span>
                  </div>
                </div>
                <br />

                <br />
              </div>
              <div
                style={{
                  ...kioskstyles.flexPanelLayoutB_BotContainer,
                  //position: "fixed",
                  bottom: "10.5vh"
                }}
              >
                <KioskStepper activeStep={0} backUrl={"/language"} />
              </div>
            </div>
          </div>
        </FlexLeftPanel>
        <FlexRightPanel isNotMobile={isNotMobile}>
          <div
            id="rightpanelcontents"
            style={kioskstyles.flexRightPanelContents}
          >
            <div
              style={{
                ...kioskstyles.flexParentPanel,
                marginLeft: "10vh, clamp(20px, 3vw, 95px)",
                marginRight: "10vh, clamp(20px, 3vw, 95px)"
              }}
            >
              <div style={kioskstyles.flexPanelLayoutD}>
                <SubtitleWrapper isNotMobile={isNotMobile}>
                  <Translate value="application.phonenumber.signinwithphone" />
                </SubtitleWrapper>
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    fontSize: "large"
                  }}
                >
                  <Translate value="application.phonenumber.enterslowly" />
                </div>

                <div
                  id="phonedisplay"
                  style={{
                    display: "flex",
                    //marginTop: "10.5vh",
                    width: "100%",
                    justifyContent: "center"
                  }}
                >
                  <PhoneDisplay value={phonenumber} />
                  <BackspaceButton
                    show={!showconfirmdialog}
                    onClick={backspacepress}
                  />
                </div>

                <div
                  id="PhoneBlock"
                  style={{ width: "100%", height: "52.8vh" }} // Used to keep the model and keypad lined up
                >
                  <PhoneKeypad
                    phonenumber={phonenumber}
                    setPhonenumber={setPhonenumber}
                    keypadDone={keypadDone}
                    loading={loading}
                    errorMessage={errorMessage}
                    showconfirmdialog={showconfirmdialog}
                  />
                  <PhoneConfirm
                    phonenumber={phonenumber}
                    showconfirmdialog={showconfirmdialog}
                    hideConfirmDialog={hideConfirmDialog}
                    next={next}
                  />
                </div>
              </div>
            </div>
          </div>
        </FlexRightPanel>
      </Grid>
      <IdleTimerDisplay />
    </IdleTimerProvider>
  );
}
