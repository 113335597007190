import * as React from "react";

import { Button, Grid, Typography, IconButton, TextField } from "@mui/material";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import BackspaceIcon from "@mui/icons-material/Backspace";
import CircleButton from "./../Buttons/CircleButton";
import PhoneDisplay from "./PhoneDisplay";

import { useTheme } from "@mui/material/styles";

// props has activeStep
export default function PhoneKeypad(props) {
  const theme = useTheme();

  const telephonebuttonpress = event => {
    //console.log("telephonebuttonpress", event);

    let newNumber = props.phonenumber + event.target.innerText;

    props.setPhonenumber(newNumber.substring(0, 10));
  };

  const backspacepress = event => {
    let newNumber = props.phonenumber;

    if (newNumber.length > 0) {
      newNumber = newNumber.substring(0, newNumber.length - 1);
    }

    props.setPhonenumber(newNumber);
  };

  const clearbuttonpress = event => {
    //console.log("clearbuttonpress", event);
    props.setPhonenumber("");
  };

  if (props.showconfirmdialog) {
    return <></>;
  }

  return (
    <div
      // style={{
      //   display: "flex",
      //   flexDirection: "column-reverse",
      //   alignItems: "center",
      //   justifyContent: "space-between",
      //   height: "100%",
      //   backgroundColor: "yellow"
      // }}
      id="fillout_keyanderrmessage"
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignContent: "space-between",
        flexWrap: "wrap",
        justifyContent: "center"
      }}
    >
      {/* <div>
        <br /> */}
      <div
        id="keypad"
        style={{ display: "flex", flexDirection: "column", width: "100%" }}
      >
        <div
          key={"telephoneButRow1"}
          style={{
            marginBottom: "3px",
            display: "flex",
            justifyContent: "center",
            gap: "1vh"
          }}
        >
          <CircleButton PercentSize="10%" onClick={telephonebuttonpress}>
            1
          </CircleButton>
          <CircleButton PercentSize="10%" onClick={telephonebuttonpress}>
            2
          </CircleButton>
          <CircleButton PercentSize="10%" onClick={telephonebuttonpress}>
            3
          </CircleButton>
        </div>
        <div
          key={"telephoneButRow2"}
          style={{
            marginBottom: "3px",
            display: "flex",
            justifyContent: "center",
            gap: "1vh"
          }}
        >
          <CircleButton PercentSize="10%" onClick={telephonebuttonpress}>
            4
          </CircleButton>
          <CircleButton PercentSize="10%" onClick={telephonebuttonpress}>
            5
          </CircleButton>
          <CircleButton PercentSize="10%" onClick={telephonebuttonpress}>
            6
          </CircleButton>
        </div>
        <div
          key={"telephoneButRow3"}
          style={{
            marginBottom: "3px",
            display: "flex",
            justifyContent: "center",
            gap: "1vh"
          }}
        >
          <CircleButton PercentSize="10%" onClick={telephonebuttonpress}>
            7
          </CircleButton>
          <CircleButton PercentSize="10%" onClick={telephonebuttonpress}>
            8
          </CircleButton>
          <CircleButton PercentSize="10%" onClick={telephonebuttonpress}>
            9
          </CircleButton>
        </div>
        <div
          key={"telephoneButRow4"}
          style={{
            marginBottom: "3px",
            display: "flex",
            justifyContent: "center",
            gap: "1vh"
          }}
        >
          <CircleButton
            PercentSize="10%"
            onClick={clearbuttonpress}
            color="error"
          >
            <CloseIcon sx={{ fontSize: 40 }} />
          </CircleButton>
          <CircleButton PercentSize="10%" onClick={telephonebuttonpress}>
            0
          </CircleButton>
          <CircleButton
            disabled={props.loading}
            onClick={props.keypadDone}
            PercentSize="10%"
            color="success"
          >
            <CheckIcon sx={{ fontSize: 40 }} />
          </CircleButton>
        </div>
      </div>
      <div
        id="phoneerror"
        style={
          {
            //height: "clamp(12px, 2vw, 26px)",
            //width: "100%"
            //padding: "0.5vw 2vw 0.5vw 1.5vw" //matches padding of stepper buttons
          }
        }
      >
        <Typography
          variant="body2"
          style={{
            color: "red",
            //marginTop: "clamp(0px, 12vw, 24px)",
            fontSize: "clamp(12px, 1.6vw, 26px)",
            //position: "absolute",
            //height: "0px"
            //right: "8vw"
            // width: "100%",
            // maxWidth: "800px",
            // marginRight: "auto"
            width: "100%",
            //height: "clamp(12px, 1.6vw, 26px)"
            height: "clamp(12px, 2vw, 26px)"
          }}
        >
          {props.errorMessage}
        </Typography>
      </div>
      {/* </div> */}
      {/* <div id="phonedisplay" style={{ display: "flex", marginTop: "10.5vh" }}>
        <PhoneDisplay value={props.phonenumber} />
        <BackspaceIcon
          fontSize="large"
          style={{ marginLeft: "13px" }}
          onClick={backspacepress}
        />
      </div> */}
    </div>
  );
}
