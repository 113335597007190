import React, { useContext, useState, useEffect } from "react";
import _ from "lodash";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import {
  setTranslations,
  Translate,
  setLocale,
  getLocale,
  translate
} from "react-i18nify";

import { kioskstyles } from "./../../kioskstyles";
import KioskStylesReact from "./../../KioskStylesReact";

import {
  Button,
  Grid,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
  OutlinedInput,
  ButtonGroup
} from "@mui/material";

import Logo from "./Components/Logo";
import KioskStepper from "./Components/Stepper/KioskStepper";
import LanguageButtons from "./Components/Shared/LanguageButtons";
import SplashImage from "./Components/LanguageSelect/SplashImage";
import SubtitleWrapper from "./Components/Shared/PanelElements/SubtitleWrapper";
import FlexLeftPanel from "./Components/Shared/PanelElements/FlexLeftPanel";
import FlexRightPanel from "./Components/Shared/PanelElements/FlexRightPanel";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { useTheme } from "@mui/material/styles";

import { Dlog } from "./../../utils/Dlog";

import HttpContext from "../../contexts/HTTP/HttpContext";
import KioskContext from "../../contexts/Kiosk/KioskContext";
import SocketIOContext from "../../contexts/Kiosk/SocketProvider/SocketIOContext";

import { useNavigate } from "react-router-dom";
import { flexbox } from "@mui/system";

import "./../../font.css";

//import HorizontalLayout from "./Components/ALanguageSelect/HorizontalLayout";
import VerticalLayout from "./Components/ALanguageSelect/VerticalLayout";
import HorizontalLayout from "./Components/ALanguageSelect/HorizontalLayout";

let env = require("./../../env");
let moment = require("moment-timezone");

function NumberOfBags({ authenticate }) {
  const theme = useTheme();
  const { post } = useContext(HttpContext);

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [loginError, setLoginError] = useState("");

  const [qrcode, setQrcode] = useState("");

  const [intervalTimer, setIntervalTimer] = useState(null);

  const {
    //customerName,
    customerFirstName,
    customerLastName,
    customerID,
    setCustomerNameAndID,
    numBags,
    setNumBags,
    numLabels,
    setLabels,
    setSessionID,
    setAll,
    customerReset
  } = useContext(KioskContext);

  const {
    isConnected,
    socketEvents,
    qrCode,
    qrCodeExpireTime,
    syncTimeOffset,
    connect,
    disconnect,
    getSocket,
    sendEvent,
    sendQRCodeCheck,
    clearSocketEvents
  } = useContext(SocketIOContext);

  const navigate = useNavigate();

  Dlog(
    "DEBUG context Dump 1",
    customerFirstName,
    customerLastName,
    customerID,
    numBags,
    numLabels
  );

  //Logic for response from Socket.IO
  useEffect(() => {
    Dlog("I think an event happened! (ALanguageSelect)", socketEvents);
    if (socketEvents == null) {
      console.log("socketEvents is null, ignoring");
      return;
    }

    let socketdata = socketEvents.data;
    Dlog("socketdata", socketdata);

    let action = socketdata.action;

    switch (action) {
      // case "QRCODE_REFRESH":
      //   console.log("TODO QRCODE_REFRESH");
      //   break;
      case "CAAPP_LOGIN":
        let newSessionID = generateSessionID();

        Dlog(
          "Got this data",
          socketdata.data.firstName,
          socketdata.data.lastName,
          socketdata.data.uniqueID
        );

        setAll(
          socketdata.data.firstName, //customerFirstName
          socketdata.data.lastName, //customerLastName
          socketdata.data.uniqueID, //customerID
          null, //numBags
          null, //numLabels
          null, //labelsList
          newSessionID //sessionID
        );

        navigate("/numbags"); //skip phonenumber input
        clearSocketEvents();
        break;
      case "RELOGIN":
        let kioskID = localStorage.getItem("kioskID");
        let subscriptionID = localStorage.getItem("subscriptionID");

        let payload = {
          type: "DATAUPDATE",
          state: "ALANGUAGESELECT",
          subscriptionID: subscriptionID,
          kioskID: kioskID
        };

        //sendEvent(payload, onEventSent);
        let socket = getSocket();

        socket.emit("event", payload);
        break;
    }
  }, [socketEvents]);

  const navigateNext = event => {
    generateNewSessionIDAndNavigateNext();
  };

  const generateNewSessionIDAndNavigateNext = () => {
    let newSessionID = generateSessionID();

    setAll(
      null, //customerFirstName
      null, //customerLastName
      null, //customerID
      null, //numBags
      null, //numLabels
      null, //labelsList
      newSessionID //sessionID
    );

    navigate("/phonenumber");
  };

  const isNotMobile = useMediaQuery("(min-width:600px)");
  const isLandscape = useMediaQuery("(orientation:landscape)");

  if (isLandscape) {
    return (
      <HorizontalLayout
        nextFunc={navigateNext}
        loading={loading}
        isNotMobile={isNotMobile}
      />
    );
  } else {
    return (
      <VerticalLayout
        nextFunc={navigateNext}
        loading={loading}
        isNotMobile={isNotMobile}
      />
    );
  }

  return (
    <VerticalLayout
      //setKioskkeyFunc={setKioskkey}
      nextFunc={navigateNext}
      loading={loading}
      //errorMessage={errorMessage}
      isNotMobile={isNotMobile}
      //kioskkey={kioskkey}
    />
    // <Grid
    //   container
    //   rowSpacing={1}
    //   columnSpacing={{ xs: 1, sm: 2, md: 3 }}
    //   style={kioskstyles.GridFullHeightFix}
    // >
    //   <Grid item xs={12} sm={6} style={KioskStylesReact().flexLeftPanel}>
    //     <div style={kioskstyles.flexLeftPanelContents}>
    //       <div style={kioskstyles.flexParentPanel}>
    //         <div
    //           style={{
    //             ...kioskstyles.flexPanelLayoutD,
    //             justifyContent: "center",
    //             height: "100%"
    //           }}
    //         >
    //           <Logo />
    //           <div
    //             style={{
    //               textAlign: "center"
    //             }}
    //             onClick={navigateNext}
    //           >
    //             <div
    //               class="fontBold"
    //               style={{
    //                 fontSize: "clamp(1.5rem, 9.5vh, 4.3rem)",
    //                 textAlign: "center",
    //                 color: "white",
    //                 marginTop: "-6vh" //fix for text not reaching to top of div
    //               }}
    //             >
    //               <Translate value="application.languageselect.kioskname" />
    //             </div>
    //             <br />
    //             <Button
    //               variant="outlined"
    //               style={{
    //                 backgroundColor: "white",
    //                 color: "rgb(39, 167, 55)",
    //                 fontSize: "clamp(0.7rem, 4vh, 2.5rem)",
    //                 fontStyle: "italic",
    //                 border: "0px solid white"
    //               }}
    //             >
    //               <Translate value="application.languageselect.presstostart" />
    //             </Button>
    //           </div>
    //           <LanguageButtons AdditionalOnClick={navigateNext} />
    //         </div>
    //       </div>
    //     </div>
    //   </Grid>
    //   <Grid
    //     item
    //     xs={12}
    //     sm={6}
    //     style={KioskStylesReact().flexRightPanel}
    //     onClick={navigateNext}
    //   >
    //     <div style={kioskstyles.flexRightPanelContents}>
    //       <div style={kioskstyles.flexParentPanel}>
    //         <div
    //           style={{
    //             ...kioskstyles.flexPanelLayoutD,
    //             justifyContent: "center",
    //             height: "100%"
    //           }}
    //         >
    //           <SubtitleWrapper isNotMobile={isNotMobile}></SubtitleWrapper>
    //           <div style={{ height: "calc(100% - clamp(0px, 3.2vi, 48px))" }}>
    //             <SplashImage
    //               trigger={translate("application.languageselect.presstostart")}
    //             />
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </Grid>
    // </Grid>
  );
}

function generateSessionID() {
  let newSessionID = null;
  if (crypto.randomUUID != null) {
    newSessionID = crypto.randomUUID();
  } else {
    // in the case of http, randomUUID is not available; This case should be used on the servers, as the servers should redirect to https.
    console.log(
      "[WARNING] crypto.randomUUID doesn't exist, HTTPS is probably not enabled. Using fallback."
    );
    newSessionID = mockUniqueID(32);
  }
  return newSessionID;
}

function mockUniqueID(length) {
  let chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  var result = "";
  for (var i = length; i > 0; --i)
    result += chars[Math.floor(Math.random() * chars.length)];
  return result;
}

export default NumberOfBags;
