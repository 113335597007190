import * as React from "react";
import BackspaceIcon from "@mui/icons-material/Backspace";

export default function BackspaceButton(props) {
  const { show, onClick } = props;

  if (!show) {
    return <div></div>;
  }

  return (
    <div style={{ display: "block", marginTop: "auto", marginBottom: "auto" }}>
      <BackspaceIcon
        fontSize="large"
        style={{
          marginLeft: "13px"
        }}
        onClick={onClick}
      />
    </div>
  );
}
