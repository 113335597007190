import * as React from "react";
import Add from "@mui/icons-material/Add";
import Circle from "@mui/icons-material/Circle";
import Delete from "@mui/icons-material/Delete";
import InfoIcon from "@mui/icons-material/Info";

import { formatDate } from "../../../utils/misc";

import {
  Button,
  Divider,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  colors,
  Paper,
  IconButton,
  TextField
} from "@mui/material";

function BagtagLogRow(props) {
  const { row, key } = props;

  console.log(row, key);

  return (
    <TableRow key={key}>
      <TableCell align="left">{row.bagtag}</TableCell>
      <TableCell align="left">
        {formatDate(row.timeStamp, "YYYY-MMM-DD HH:mm:ss")}
      </TableCell>
      <TableCell align="left">{row.collectorCode}</TableCell>
      <TableCell align="left">{row.numBags}</TableCell>
      <TableCell align="left">{row.numLabels}</TableCell>
      <TableCell align="left">{row.kioskID}</TableCell>
      {/* <TableCell align="left">{row.subscriptionID}</TableCell> */}
      {/* <TableCell align="left">{row.locationCode}</TableCell> */}
      {/* <TableCell align="left">{row.locationName}</TableCell>
      <TableCell align="left">{row.locationAddress}</TableCell> */}
    </TableRow>
  );
}

export default BagtagLogRow;
