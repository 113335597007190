import React, { useContext, useEffect, useState } from "react";
import _ from "lodash";

import {
  Button,
  Divider,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  colors,
  Paper,
  IconButton,
  TextField
} from "@mui/material";

import { useTheme } from "@mui/material/styles";

import RefreshIcon from "@mui/icons-material/Refresh";

import ContainerLoading from "../Loading/ContainerLoading";

import { formatDate } from "../../utils/misc";

import UpdateLogRow from "./SocketMonitor/UpdateLogRow";

import { KEY_NAME_REGEX } from "../../constants";

import HttpContext from "../../contexts/HTTP/HttpContext";

let env = require("../../env");
const APP_URL = env.REACT_SCOCKETIO_URL;

function SocketMonitorAPI() {
  const theme = useTheme();
  const { get } = useContext(HttpContext);

  const [loading, setLoading] = useState(false);

  const [allLogs, setAllLogs] = useState([]);

  const refresh = async () => {
    // Fetch and display socket info
    setLoading(true);
    const res = await get("/kiosk/getSockets");
    setAllLogs(res.data);
    setLoading(false);
  };

  return (
    <Grid
      style={{
        margin: theme.spacing(2),
        padding: theme.spacing(3),
        width: "100%",
        backgroundColor: colors.grey[100],
        borderRadius: "10px"
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="h4">
            Socket Monitor [{allLogs.length}]
          </Typography>
          <Typography>Sync updates from CA App</Typography>
        </div>
        <div>
          <Button variant="contained" onClick={() => refresh()}>
            Refresh Info
          </Button>
        </div>
      </div>
      <Divider
        style={{ marginBottom: theme.spacing(2), marginTop: theme.spacing(2) }}
      />
      <ContainerLoading show={loading} />
      <TableContainer>
        <Table
          style={{
            marginTop: theme.spacing(2),
            display: loading ? "none" : null
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell align="left">SocketID</TableCell>
              <TableCell align="left">Data</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allLogs.map(row => (
              <UpdateLogRow row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}

export default SocketMonitorAPI;

function isKeyNameValid(name) {
  if (!name) return false;

  return KEY_NAME_REGEX.test(name);
}

function isKeyNameDuplicate(keys, name) {
  return keys.map(key => key.name).includes(name);
}

function getKeyNameHelperText(keys, name) {
  if (!isKeyNameValid(name)) {
    return "Invalid name";
  }

  if (isKeyNameDuplicate(keys, name)) {
    return "Name already in use";
  }

  return "";
}
