import React from "react";
import { kioskstyles } from "./../../../../../kioskstyles";
import { Typography, Grid } from "@mui/material";

function FlexLeftPanel(props) {
  const isNotMobile = props.isNotMobile;
  const colorOverwrite = props.colorOverwrite;
  let panelStyle = kioskstyles.flexLeftPanel;
  let moblePanelStyle = kioskstyles.flexLeftPanelMobile;

  if (colorOverwrite !== undefined) {
    panelStyle = {
      ...kioskstyles.flexLeftPanel,
      backgroundColor: colorOverwrite
    };
    moblePanelStyle = {
      ...kioskstyles.flexLeftPanelMobile,
      backgroundColor: colorOverwrite
    };
  }

  if (isNotMobile) {
    return (
      <Grid item xs={12} sm={6} style={panelStyle}>
        {props.children}
      </Grid>
    );
  } else {
    return (
      <Grid item xs={12} sm={6} style={moblePanelStyle}>
        {props.children}
      </Grid>
    );
  }
}

export default FlexLeftPanel;
