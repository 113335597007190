import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import CancelIcon from "@mui/icons-material/Cancel";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { Translate, Localize, getLocale } from "react-i18nify";

import { kioskstyles } from "./../../../../kioskstyles";

import { StepConnector } from "@mui/material";

const steps = ["", "", "", ""];

// props has activeStep
export default function KioskStepper(props) {
  const navigate = useNavigate();

  const pressBack = () => {
    if (props.backUrl !== undefined) {
      navigate(props.backUrl);
    }
  };

  const pressEnd = () => {
    navigate("/language");
  };

  return (
    <div
      style={{
        maxWidth: "490px", //Same as blackCornedDiv
        width: "41vw" //TODO: use minWidth, if mobile
      }}
    >
      <Box
        sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}
      >
        <Button
          variant="contained"
          disabled={props.backUrl == undefined}
          onClick={pressBack}
          style={kioskstyles.StepperButtonBack}
        >
          <KeyboardBackspaceIcon
            fontSize="small"
            style={{ marginRight: "10px" }}
          />
          {/* &nbsp; &nbsp; */}
          <span class="fontSecondaryText" style={{}}>
            <Translate value="application.back" />
          </span>
        </Button>
        <Stepper
          activeStep={props.activeStep}
          style={{ width: "50%", paddingLeft: "8px", paddingRight: "8px" }}
          connector={<StepConnector />}
        >
          {steps.map((label, index) => (
            <Step
              key={index}
              style={{ paddingLeft: "0px", paddingRight: "0px" }}
            >
              <StepButton color="inherit" style={{}}>
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
        <Button
          variant="contained"
          onClick={pressEnd}
          style={kioskstyles.StepperButtonEnd}
        >
          <CancelIcon fontSize="small" style={{ marginRight: "10px" }} />
          <span class="fontSecondaryText" style={{}}>
            <Translate value="application.end" />
          </span>
        </Button>
      </Box>
    </div>
  );
}
