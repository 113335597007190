import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { kioskstyles } from "./../../../../../kioskstyles";
import { Typography } from "@mui/material";

function SubtitleWrapper(props) {
  const isNotMobile = props.isNotMobile;

  if (isNotMobile) {
    return (
      <div
        id="LogoTextAlignmentMatcher"
        style={kioskstyles.LogoTextAlignmentMatcher}
      >
        <div
          id="textAligner"
          style={{
            bottom: "-0.4vi",
            position: "relative"
          }}
        >
          <Typography variant="h4" style={kioskstyles.RightPanelTitle}>
            {props.children}
          </Typography>
        </div>
      </div>
    );
  } else {
    return (
      <div
        id="LogoTextAlignmentMatcher"
        style={kioskstyles.LogoTextAlignmentMatcherMobile}
      >
        <div
          id="textAligner"
          style={{ bottom: "-0.4vi", position: "relative" }}
        >
          <Typography variant="h4" style={kioskstyles.RightPanelTitleMobile}>
            {props.children}
          </Typography>
        </div>
      </div>
    );
  }
}

export default SubtitleWrapper;
