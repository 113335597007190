import { CircularProgress, Container } from "@mui/material";
import * as React from "react";

function ContainerLoading(props) {
  const { show } = props;

  if (!show) return null;

  return (
    <Container
      style={{
        height: "25%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress />
    </Container>
  );
}

export default ContainerLoading;
