import React from "react";
import BagtagLabel from "./BagtagLabel";
import { printstyles } from "./../../../printstyles";
import "./../../../print.css";

// Using a functional component, you must wrap it in React.forwardRef, and then forward the ref to
// the node you want to be the root of the print (usually the outer most node in the ComponentToPrint)
// https://reactjs.org/docs/refs-and-the-dom.html#refs-and-function-components
export const LabelsToPrint = React.forwardRef(
  ({ labelsList, customerName }, ref) => {
    return (
      <div ref={ref} className="printOnly">
        {labelsList.map(d => (
          <BagtagLabel customerName={customerName} label={d} />
        ))}
      </div>
    );
  }
);
