const ver = require("./env_versioning");

let env = {};

env.REACT_APP_ENV = "PROD";
env.REACT_APP_NAME = "KIOSK";
env.REACT_APP_VERSION = ver.VERSION;
env.REACT_APP_BUILDNUMBER = ver.BUILDNUMBER;
env.REACT_APP_LANG = "en";
env.REACT_APP_TIMEZONE = "America/Edmonton";
env.REACT_APP_API_KEYS_ENABLED = true;
env.REACT_APP_URL = "https://kiosk.consignaction.ca";
env.REACT_SCOCKETIO_URL = "https://kiosk.consignaction.ca";
env.REACT_APP_API_ENDPOINT = "https://kiosk.consignaction.ca/api";
env.CAAPP_URL = "https://app.consignaction.ca";
env.IDLETIMER = true;
env.SHOWCONSOLELOGS = false;

module.exports = env;
