import React from "react";
import { Translate } from "react-i18nify";
import { kioskstyles } from "./../../../../kioskstyles";
import "./../../../../font.css";

// Using a functional component, you must wrap it in React.forwardRef, and then forward the ref to
// the node you want to be the root of the print (usually the outer most node in the ComponentToPrint)
// https://reactjs.org/docs/refs-and-the-dom.html#refs-and-function-components
function KioskDetail(props) {
  const { customerName, label } = props;

  const locationAddress = localStorage.getItem("LocationAddress");
  const locationName = localStorage.getItem("LocationName");

  const isNullOrWhitespace = input => {
    return !input || !input.trim();
  };

  if (isNullOrWhitespace(locationName) || isNullOrWhitespace(locationAddress)) {
    return <></>;
  }

  return (
    <div class="fontSecondaryText" style={kioskstyles.whiteBodyText}>
      <Translate
        value="application.kioskdetail.youareat"
        name={locationName}
        address={locationAddress}
      />
    </div>
  );
}

export default KioskDetail;
