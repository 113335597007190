import * as React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";

import { Dlog } from "./utils/Dlog";

// Depreicated

const flexParentPanel = {
  //backgroundColor: "lightgrey"
};

/* flexbox centered colmuns */
const flexLeftPanel = {
  backgroundColor: "rgb(39, 167, 55)",
  //backgroundImage: "url(/img/BottlePattern.svg)",  //as per https://gitlab.com/tgayef/lt3-kiosk/-/issues/28
  backgroundSize: "310px 310px",
  backgroundRepeat: "repeat",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100vh",
  paddingLeft: "0px",
  paddingTop: "0px" //Fix for Grid leaving space at the bottom
  //maxWidth: "800px",
  //marginLeft: "auto"
};

const flexRightPanel = {
  backgroundColor: "white",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100vh",
  paddingLeft: "0px",
  paddingTop: "0px" //Fix for Grid leaving space at the bottom
  //maxWidth: "800px",
  //marginRight: "auto"
};

const flexLeftPanelMobile = {
  backgroundColor: "rgb(39, 167, 55)",
  //backgroundImage: "url(/img/BottlePattern.svg)",
  backgroundSize: "310px 310px",
  backgroundRepeat: "repeat",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100vh",
  paddingLeft: "0px",
  width: "100vw"
};

const flexRightPanelMobile = {
  backgroundColor: "white",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100vh",
  paddingLeft: "0px",
  width: "100vw"
};

/* layouts for centered colmuns */

// /* layout A: One element Center */
// const flexPanelLayoutA_CenterContainer = {
//   height: "100vh",
//   //backgroundColor: "blue",
//   display: "flex",
//   flexDirection: "column",
//   alignItems: "center",
//   justifyContent: "center"
// };

// /* layout B: 3 element layout */
// const flexPanelLayoutB_TopContainer = {
//   height: "30vh",
//   //backgroundColor: "blue",
//   display: "flex",
//   flexDirection: "column",
//   alignItems: "center",
//   justifyContent: "center"
// };

// const flexPanelLayoutB_MidContainer = {
//   height: "45vh",
//   //backgroundColor: "grey",
//   display: "flex",
//   flexDirection: "column",
//   alignItems: "center",
//   justifyContent: "center"
// };

// const flexPanelLayoutB_BotContainer = {
//   height: "25vh",
//   //backgroundColor: "blue",
//   display: "flex",
//   flexDirection: "column",
//   alignItems: "center",
//   justifyContent: "center"
// };

// /* layout C: 2 element layout */
// const flexPanelLayoutC_TopContainer = {
//   height: "30vh",
//   //backgroundColor: "blue",
//   display: "flex",
//   flexDirection: "column",
//   alignItems: "center",
//   justifyContent: "center"
// };

// const flexPanelLayoutC_MidContainer = {
//   height: "70vh",
//   //backgroundColor: "blue",
//   display: "flex",
//   flexDirection: "column",
//   alignItems: "center"
//   // justifyContent: "center"
// };

// const blackCornedDiv = {
//   borderRadius: "25px",
//   backgroundColor: "black",
//   color: "white",
//   maxWidth: "370px",
//   width: "35vw",
//   height: "100%",
//   padding: "17px 30px",
//   display: "flex",
//   flexDirection: "column",
//   alignItems: "center",
//   justifyContent: "center"
// };

// const leftRightMargins = {
//   marginLeft: "6vw",
//   marginRight: "6vw"
// };

// const versioning = {
//   position: "fixed",
//   left: "3vw",
//   bottom: "3vh",
//   color: "rgba(255,255,255, 0.8)"
// };

// const textButtons = {
//   margin: "16px",
//   paddingLeft: "3vw",
//   paddingRight: "3vw",
//   //fontSize: "1.6vw"
//   fontSize: "clamp(12px, 1.6vw, 28px)"
// };

// // Text seems to start offscreen, extra margin on the left is to offset this
// const textAlignFix = {
//   // marginTop: "2vw",
//   // marginLeft: "6vw",
//   // marginRight: "2vw"
//   marginTop: "clamp(3px, 2vw, 28px)",
//   marginLeft: "clamp(3px, 6vw, 1px)",
//   marginRight: "clamp(3px, 2vw, 28px)"
// };

// //This includes the textAlignFix above
// const kioskDetails = {
//   display: "flex",
//   flexDirection: "column",
//   alignItems: "flex-start",
//   height: "100%",
//   marginTop: "clamp(0px, 2vw, 27px)",
//   marginLeft: "clamp(3px, 6vw, 80px)",
//   marginRight: "clamp(0px, 2vw, 27px)"
// };

export default function KioskStylesReact() {
  const isMobile = useMediaQuery("(min-width:600px)");
  Dlog("KioskStylesReact.js isMobile", isMobile);

  return {
    flexParentPanel: flexParentPanel,
    flexLeftPanel: isMobile ? flexLeftPanel : flexLeftPanelMobile,
    flexRightPanel: isMobile ? flexRightPanel : flexRightPanelMobile //,
    // flexPanelLayoutA_CenterContainer: flexPanelLayoutA_CenterContainer,
    // flexPanelLayoutB_TopContainer: flexPanelLayoutB_TopContainer,
    // flexPanelLayoutB_MidContainer: flexPanelLayoutB_MidContainer,
    // flexPanelLayoutB_BotContainer: flexPanelLayoutB_BotContainer,
    // flexPanelLayoutC_TopContainer: flexPanelLayoutC_TopContainer,
    // flexPanelLayoutC_MidContainer: flexPanelLayoutC_MidContainer,
    // textAlignFix: textAlignFix,
    // kioskDetails: kioskDetails,
    // leftRightMargins: leftRightMargins,
    // versioning: versioning,
    // textButtons: textButtons,

    // blackCornedDiv: blackCornedDiv
  };
}

// root: {
//   background: "white",

//   "@media (max-width: 1920px)": {
//     background: "red",
//   }
//  }
