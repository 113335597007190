import React, { useContext } from "react";

import { kioskstyles } from "./../../../../kioskstyles";
import { Translate } from "react-i18nify";

import { Button, Grid, Typography, IconButton, TextField } from "@mui/material";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CircleButton from "./../Buttons/CircleButton";
import PhoneDisplay from "./PhoneDisplay";

import KioskContext from "../../../../contexts/Kiosk/KioskContext";

const steps = ["", "", "", ""];

// props has activeStep
export default function PhoneConfirm(props) {
  const { customerFirstName, customerLastName } = useContext(KioskContext);

  if (!props.showconfirmdialog) {
    return <></>;
  }

  return (
    <div
      id="space_div_and_buttons"
      style={{
        width: "100%",
        height: "50.5vh",
        display: "flex",
        //flexDirection: "column",
        justifyContent: "center",
        alignContent: "space-between",
        flexWrap: "wrap"
      }}
    >
      <div
        style={{
          //height: "30vh",
          marginBottom: "0px",
          //minHeight: "250px",
          width: "100%"
        }}
      >
        {/* <center>
          <div style={{}}>
            <PhoneDisplay value={props.phonenumber} />
          </div>
          <br />
        </center> */}
        <div
          style={{
            //marginTop: "1vh",
            display: "flex",
            justifyContent: "center"
          }}
        >
          <div style={kioskstyles.blackCornedDivReturningContainer}>
            <Typography
              variant="h5"
              style={{
                textAlign: "center",
                fontSize: "clamp(8px, 2vw, 24px)",
                marginTop: "10px"
              }}
            >
              <Translate value="application.phonenumber.returnfor" />
            </Typography>
            <br />
            <Typography
              variant="h4"
              style={{
                fontSize: "clamp(9px, 2vw, 28px)"
              }}
            >
              {customerFirstName + " " + customerLastName}
            </Typography>
          </div>
        </div>
      </div>
      <div>
        <div
          style={
            {
              //marginTop: "clamp(20px, 5vh, 50px)"
            }
          }
        >
          <div
            style={{
              marginTop: "clamp(8px, 2vh, 28px)",
              marginBottom: "clamp(8px, 2vh, 28px)",
              textAlign: "center"
            }}
          >
            <span
              class="fontBold"
              //variant="h4"
              style={{
                fontSize: "clamp(20px, 3vw, 28px)"
              }}
            >
              <Translate value="application.phonenumber.isthiscorrect" />
            </span>
          </div>
        </div>
        <div
          key={"telephoneButRow4"}
          style={{
            marginTop: "clamp(0px, 5vh, 20px)",
            marginBottom: "3px",
            display: "flex",
            justifyContent: "center",
            gap: "1vh"
          }}
        >
          <CircleButton
            PercentSize="10%"
            onClick={props.hideConfirmDialog}
            color="error"
          >
            <Translate value="application.no" />
          </CircleButton>
          <div style={{ marginLeft: "60px" }} />
          <CircleButton onClick={props.next} PercentSize="10%" color="success">
            <Translate value="application.yes" />
          </CircleButton>
        </div>
        <div
          id="phoneerror_spacer"
          style={{
            height: "clamp(12px, 1.6vw, 26px)", //Matches height of phoneerror in PhoneKeypad
            margin: "0.5vw 2vw 0.5vw 1.5vw", //matches padding of stepper buttons
            width: "100%"
          }}
        ></div>
      </div>
    </div>
  );
}
